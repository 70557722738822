@keyframes slideInRight {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes slideOutLeft {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
  
  .slide-in-right {
    animation: slideInRight 0.5s forwards;
  }
  
  .slide-out-left {
    animation: slideOutLeft 0.5s forwards;
  }
  