.card-container {
  width: 100%;
  max-width: 400px; /* Set max width of the card */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 20px;
}
.upper-part{
  margin-bottom: 10px;
}
.upper-part, .lower-part {
  width: 100%;
  height: 200px;
  
  background-color: #000; /* Light gray background */
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.logo-wrapper {
  width: 60px; /* Slightly larger to account for padding */
  height: 60px;
  padding: 5px; /* Padding to create space around the logo */
  border-radius: 30px; /* Ensures the padded area is circular */
  background-color: white; /* Same as the card background */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 25px; /* Circular logo */
  object-fit: cover;
  background-color: red;
}

/* Clip path for upper and lower parts to create the cut-out for the logo */
.upper-part::after, .lower-part::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 60px;
  height: 30px;
  background-color: #f8f9fa;
  
  transform: translate(-50%, -50%);
  border-radius: 0 0 30px 30px;
  z-index: -1;
}

.lower-part::after {
  top: 0;
  border-radius: 30px 30px 0 0;
}
