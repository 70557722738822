
  .link:hover {
    border-radius: 4px solid white;
    background: #ede1e1;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
 
   
 
 
  